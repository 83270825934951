// Search
/*function searchState(state) {
    if (state == 'mediaDesktop') {
        $('.search-box__wrapper').dropDownMenu({
            disabled:    true,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    } else if ((state == 'mediaSmartphone') || (state == 'mediaTablet')) {
        //$('#searchform .dropDownMenu').dropDownMenu(reset);
        $('.search-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
}*/

function searchState(state) {
    if ((state == 'mediaSmartphone') || (state == 'mediaTablet') || (state == 'mediaDesktop')) {
        //$('#searchform .dropDownMenu').dropDownMenu(reset);
        $('.search-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
}

function closeOverlaySearch() {
    $('.search-box__close').on('click', function(){
        $('.search-box__wrapper').removeClass('ddm--active');
        $('#search .button-1').attr('aria-expanded', 'false');
        $('#search .search-box_fields').attr('aria-hidden', 'true');
    });
}