var content = function() {

    // Add a container around table
    var table = document.querySelectorAll('.rte table');

    if (table) {
        for ( var z = 0; z < table.length; z++) {
            var originTable = table[z].outerHTML;
            var wrapTable = "<div class=\"table-wrapper\"><div class=\"table-wrapper-fade\"></div><div class=\"table-wrapper-inner\">" + originTable + "</div></div>";
            table[z].outerHTML = wrapTable;
        }
    }

    var init = {
        table: function() {
            var mainContent = document.getElementsByClassName('section-main__content');
            var tableContainer = document.getElementsByClassName('table-wrapper-inner');

            for ( var i = 0; i < mainContent.length; i++) {
                for ( var j = 0; j < tableContainer.length; j++) {
                    tableContainer[j].style.width = null;
                    var widthmMainContent = outerWidth(mainContent[i]);
                    tableContainer[j].style.width = widthmMainContent + 'px';
                }
            }
        }
    };

    document.addEventListener("DOMContentLoaded", function() {
        init.table();
    });

    window.addEventListener("resize", function() {
        init.table();
    });
};




