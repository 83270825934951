// Caroussel2
var carouselWrap2 = $('.carrousel2'),
    carousel2 = $('.listItems', carouselWrap2),
    reinitTimer2;

//console.log(carousel2);

function carrousel2(itemVisible) {
    //console.log(itemVisible);
    if (carouselWrap2.length > 0) {
        var nbItem = $('.item', carousel2).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '4';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel2').length == 0) {
                carousel2.parent().append('<ul class="prevNextCaroussel2">' +
                    '<li class="prevCaroussel2"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel2/carrousel2-prev.png" alt="' + l10n['prev'] + '"><img src="' + pathImg + 'carrousel2/carrousel2-prev-dis.png" alt="' + l10n['prev'] + '"></button></li>' +
                    '<li class="nextCaroussel2"><button type="button" class="button-reset"><img src="' + pathImg + 'carrousel2/carrousel2-next.png" alt="' + l10n['next'] + '"><img src="' + pathImg + 'carrousel2/carrousel2-next-dis.png" alt="' + l10n['next'] + '"></button></li>' +
                    '</ul>');
            }
            //console.log(carousel2);
            carousel2.cycle({
                log:             false,
                fx:              'carousel',
                allowWrap:       false,
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCaroussel2 button',
                next:            '.nextCaroussel2 button',
                slides:          '.item',
                autoHeight:      'calc'
            });

            $('.item', carousel2).css('opacity', '1');
        }
    }
}

var destroyCarrousel2 = function () {
    $('.carrousel2 .listItems').cycle('destroy');
}

function reinit_cycle2(nbItem) {
    setTimeout(function () {
        destroyCarrousel2();
        setTimeout(function () {
            //console.log('reinit');
            carrousel2(nbItem);
        }, 200);
    }, 200);
}

function carrousel2State(view) {
    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer2);
        //console.log('mediaDesktop');
        reinitTimer2 = setTimeout(reinit_cycle2('5'), 200);
    } else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('3'), 200);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('1'), 200);
    }
}
